/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery, QueryClient } from '@tanstack/react-query';
import request from '~/bin/httpRequest';

export const req = (options, sessionIdDisable) => request.genericHandler(options, sessionIdDisable).then((res) => {
  if (res.error) {
    return { ok: false, body: res.error.data };
  }
  return { ok: true, body: res.data.data };
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const configQuery = config => () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/config/${config}`,
}, null);

export const useConfig = (config, enabled = true) => useQuery({ queryKey: [config], queryFn: configQuery(config), enabled });

export const getConfig = config => queryClient.fetchQuery({ queryKey: [config], queryFn: configQuery(config) });

const espotQuery = espot => () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/config/${espot}`,
}, null);

export const useEspot = (espot, enabled = true) => useQuery({ queryKey: [espot], queryFn: espotQuery(espot), enabled });

// export const getEspot = espot => queryClient.fetchQuery({ queryKey: [espot], queryFn: espotQuery(espot) });

export const notifyAvailability = data => req({
  method: 'POST',
  url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/notify/availability`,
  data,
}, null);

const configMCQuery = () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/config`,
  loading: false,
}, null);

const eSIMConfigQuery = () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/config/esim`,
  loading: false,
}, null);

const TermsQuery = () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/config/terms`,
  responseType: 'text',
}, null);

export const useConfigMC = (initialData) => useQuery({ queryKey: ['configMC'], queryFn: configMCQuery, initialData });

export const useTermsMC = () => useQuery({ queryKey: ['termsMC'], queryFn: TermsQuery });

export const getConfigMC = () => queryClient.fetchQuery({ queryKey: ['configMC'], queryFn: configMCQuery });

export const getEsimConfig = () => queryClient.fetchQuery({ queryKey: ['configESim'], queryFn: eSIMConfigQuery });

const itemsBySKU = (type, query, enabled = true) => {
  const params = ['itemsBySKU', query.sku.sort().join(',')];
  const fn = () => req({
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/products/items`,
    data: query,
  }, null);
  if (type === 'hook') {
    return useQuery({ queryKey: params, queryFn: fn, enabled });
  }
  return queryClient.fetchQuery({ queryKey: params, queryFn: fn });
};

// export const useItemsBySKU = (query, enabled = true) => itemsBySKU('hook', query, enabled);

export const getItemsBySKU = query => itemsBySKU('async', query);

const addons = (type, enabled = true) => {
  const params = ['addons'];
  const fn = () => req({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_WCAAS}${process.env.NEXT_PUBLIC_MERCHANT}/addon`,
    loading: false,
  }, null);
  if (type === 'hook') {
    return useQuery({ queryKey: params, queryFn: fn, enabled });
  }
  return queryClient.fetchQuery({ queryKey: params, queryFn: fn });
};

// export const useAddons = (enabled = true) => addons('hook', enabled);

export const apiAddons = () => addons('async');

const categories = (type, enabled = true) => {
  const params = ['categories'];
  const fn = () => req({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/categories/`,
  }, null);
  if (type === 'hook') {
    return useQuery({ queryKey: params, queryFn: fn, enabled });
  }
  return queryClient.fetchQuery({ queryKey: params, queryFn: fn });
};

// export const useCategories = (enabled = true) => categories('hook', enabled);

export const apiCategories = () => categories('async');

const configHome = (type, enabled = true) => {
  const params = ['configHome'];
  const fn = () => req({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/config/home`,
    loading: false,
  }, null);
  if (type === 'hook') {
    return useQuery({ queryKey: params, queryFn: fn, enabled });
  }
  return queryClient.fetchQuery({ queryKey: params, queryFn: fn });
};

// export const useConfigHome = (enabled = true) => configHome('hook', enabled);

export const getConfigHome = () => configHome('async');

export const getProductItem = partNumber => queryClient.fetchQuery({ queryKey: ['productItem', partNumber], queryFn: () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/product/item/${partNumber}`,
}, null) });

export const getImagePassport = () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/config/tarjetas-internacionales`,
}, null);

export const uploadImagePassport = data => req({
  method: 'POST',
  url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/upload/imagePassaporte`,
  data,
});
export const queryFunction = async (action, fetchQuery, nestedData = true) => {
  const res = await fetchQuery();
  const response = { action, success: false };
  if (res.ok) {
    response.data = nestedData ? { data: res.body } : res.body;
    response.success = true;
  } else {
    response.error = { data: res.body };
  }
  return response;
};
