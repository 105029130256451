import { useEffect } from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { getItemsBySKU } from '~/store/Query/api';

export const planesState = hookstate([]);

const planesNameState = hookstate({});

export const usePlanesState = (enabled) => {
  const planes = (planesState);
  useEffect(() => {
    async function init() {
      if (enabled && planes.value?.length) {
        const sku = planes.value.filter(plan => !planesNameState.value[plan]);
        if (sku.length) {
          const res = await getItemsBySKU({ sku });
          if (res.ok) {
            planesNameState.merge(res.body.reduce((obj, prod) => {
              obj[prod.items[0].part_number] = prod.items[0].locales[process.env.NEXT_PUBLIC_LOCALE].name;
              return obj;
            }, {}));
          }
        }
      }
    }
    init();
  }, [planes.value]);
  return useHookstate(planesNameState);
};
